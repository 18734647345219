<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="frontRules" v-slot="{ errors }">
    <el-form-item :error="errors[0]" :label="$attrs.label">
      <slot name="prepend"></slot>
      <el-checkbox-group v-on="inputListeners"  v-bind="$attrs" v-model="innerValue" :ref="inputRef">
        <slot/>
      </el-checkbox-group>
      <slot name="append"></slot>
    </el-form-item>
  </ValidationProvider>
</template>
<script>

import inputElemet from './mixin/inputElemet'

export default {
  mixins: [inputElemet]
}
</script>
