<template>
  <el-container class="cropper-editor h-100" :class="{ error: !isValidSize }">
    <loader-crooper
      v-if="!data.loaded"
      ref="loader"
      :data="data"
      :frontRules="options.frontRules"
      @loader-load-file="onLoadedFile"
    />

    <el-main v-if="data.loaded">
      <editor-crooper
        ref="editor"
        :data="data"
        :config="configEditor"
        :isValidSize="isValidSize"
        v-on:update-data-crooper="updateDataCrooper"
        v-on:update-cropend="updateCropend"
      />
    </el-main>

    <el-aside v-if="data.loaded" class="form-cropper" :data="data" width="30%">
      <div class="clearfix">
        <h2 class="float-left p-0">
          <i class="el-icon-picture-outline"></i> {{ options.label }}
        </h2>
      </div>

      <ValidationObserver ref="form" v-slot="{ valid }">
        <el-form :label-position="'top'" :model="formImage">
          <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
            <el-form-item :error="errors[0]" label="Title">
              <el-input v-model="formImage.title"></el-input>
            </el-form-item>
          </ValidationProvider>

          <ValidationProvider
            name="Alternate text"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              :error="errors[0]"
              label="Alternate text for an image"
            >
              <el-input v-model="formImage.alt"></el-input>
            </el-form-item>
          </ValidationProvider>

          <el-row class="mb-2">
            <el-col :span="12">
              <h3>Size requeriments</h3>
              <ul class="list-features">
                <li v-if="options.width">
                  <ValidationProvider
                    :rules="'min_value:' + options.width"
                    name="Width"
                    ref="width"
                    v-slot="{ valid, errors }"
                  >
                    <i
                      :style="valid | statusColor"
                      :class="valid | statusIcon"
                    ></i>
                    <b>Width: </b>
                    <span>{{ formImage.width }}px</span>
                    <br />
                    <b>{{ errors[0] }} </b>
                  </ValidationProvider>
                </li>

                <li v-if="options.height">
                  <ValidationProvider
                    :rules="'min_value:' + options.height"
                    name="Height"
                    ref="height"
                    v-slot="{ valid, errors }"
                  >
                    <i
                      :style="valid | statusColor"
                      :class="valid | statusIcon"
                    ></i>
                    <b>Height: </b>
                    <span>{{ formImage.height }}px</span>
                    <br />
                    <b>{{ errors[0] }} </b>
                  </ValidationProvider>
                </li>
              </ul>
            </el-col>

            <el-col :span="12">
              <h3>Features Output</h3>
              <ul class="list-features">
                <li v-if="options.key"><b>Key:</b> {{ options.key }}</li>
                <li v-if="options.aspectRatio">
                  <b>Aspect Ratio:</b> {{ options.aspectRatio }}
                </li>
                <li v-if="options.width">
                  <b>Width:</b> {{ options.width }}px
                </li>
                <li v-if="options.height">
                  <b>Height:</b> {{ options.height }}px
                </li>
                <li v-if="options.format">
                  <b>Format:</b> {{ options.format.toUpperCase() }}
                </li>
                <li v-if="options.quality">
                  <b>Quality:</b> {{ options.quality }}%
                </li>
                <li v-if="options.disk"><b>Disk:</b> {{ options.disk }}</li>
                <li v-if="options.folder">
                  <b>Folder:</b> {{ options.folder }}
                </li>
              </ul>
            </el-col>
          </el-row>

          <el-form-item>
            <el-progress v-if="uploadPercentage !== 0"  :percentage="uploadPercentage"  :status="uploadPercentage | progressColor"   ></el-progress>
            <el-button-group>
              <el-button
                v-if="data.cropped"
                type="primary"
                title="Undo (Ctrl + Z)"
                icon="fa fa-undo"
                @click="change('restore')"
                :loading="loading"
              >
                Undo
              </el-button>

              <el-button
                v-if="data.loaded && !data.cropping"
                type="primary"
                title="Delete (Ctrl + D)"
                icon="fa fa-trash"
                @click="change('remove')"
                :loading="loading"
              >
                Delete
              </el-button>
              <el-button
                v-if="data.cropping"
                type="primary"
                title="Cancel"
                icon="fa fa-ban"
                @click="change('clear')"
              >
                Cancel
              </el-button>

              <el-button
                v-if="data.cropping"
                type="primary"
                title="Crop (Enter)"
                icon="fa fa-check"
                @click="change('crop')"
                :disabled="!isValidSize"
              >
                Crop
              </el-button>

              <el-button
                v-if="data.loaded && data.cropped && isValidSize"
                type="success"
                title="Upload & Save"
                icon="fa fa-upload"
                @click="upload"
                :disabled="!valid"
                :loading="loading"
              >
                Upload & Save
              </el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </ValidationObserver>
    </el-aside>
  </el-container>
</template>

<script>

import { uploadImageBase64 } from '@/api/image'
import editor from './editor'
// import loader from './loader'
import loaderImage from '../LoaderImage'

export default {
  components: {
    'editor-crooper': editor,
    'loader-crooper': loaderImage
  },
  filters: {
    statusColor (status) {
      return status ? 'color: green' : 'color: red'
    },
    statusIcon (status) {
      return status ? 'el-icon-success' : 'el-icon-error'
    },
    progressColor (percentage) {
      if (percentage === 100) {
        return 'success'
      } else if (percentage > 60) {
        return 'warning'
      } else {
        return 'exception'
      }
    }
  },
  props: {

    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      isValidSize: false,
      uploadPercentage: 0,
      formImage: {
        title: '',
        alt: '',
        width: 0,
        height: 0
      },
      configEditor: {
        aspectRatio: NaN,
        autoCrop: false,
        viewMode: 2,
        dragMode: 'crop',
        background: true
      },
      data: {
        cropped: false,
        cropping: false,
        loaded: false,
        name: '',
        previousUrl: '',
        type: '',
        url: ''
      }
    }
  },
  created () {
    this.configEditor.aspectRatio = this.evalAspectRatio()
  },
  methods: {
    async upload () {
      if (!this.data.loaded || !this.data.cropped || !this.isValidSize) { return false }

      this.loading = true
      const dataSend = Object.assign({}, this.options, {
        title: this.formImage.title,
        alt: this.formImage.alt,
        image: this.data.url,
        cropWidth: this.formImage.width,
        cropHeight: this.formImage.height,
        fileName: this.data.name,
        mimeType: this.data.type
      })

      // var formData = new FormData()
      // for (const [key, value] of Object.entries(dataSend)) {
      //   formData.append(key, value)
      // }
      // console.log('upload -> formData', formData)

      await uploadImageBase64(dataSend, {
        onUploadProgress: (progressEvent) => {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
        }

      })
        .then((response) => {
          const res = response.data
          this.$message({
            showClose: true,
            message: 'Done!',
            type: 'success'
          })
          this.$emit('saved-image', res)

          // this.$emit('update:value', res)
        })
        .catch(this.responseCatch)
        .finally(() => {
          this.uploadPercentage = 0
          this.loading = false
        })
    },
    change (action) {
      const { editor } = this.$refs
      switch (action) {
        case 'crop':
          editor.crop()
          break
        case 'clear':
          editor.clear()
          break
        case 'restore':
          editor.restore()
          break
        case 'remove':
          editor.reset()
          break
        default:
      }
    },
    async updateDataCrooper (detail) {
      this.formImage.width = Math.round(detail.width)
      this.$refs.width.syncValue(this.formImage.width)
      const promiseWidth = this.$refs.width.validate()

      this.formImage.height = Math.round(detail.height)
      this.$refs.height.syncValue(this.formImage.height)
      const promiseHeight = this.$refs.height.validate()

      this.formImage = Object.assign({}, this.formImage)

      Promise.all([promiseWidth, promiseHeight]).then(items => {
        this.isValidSize = items[0].valid && items[1].valid
      }).catch(reason => {
        this.isValidSize = false
      })
    },
    async updateCropend (e) {
      await this.$refs.form.validate()
    },
    evalAspectRatio: function () {
      const aspect = this.options.aspectRatio.split('/', 2)

      let response = NaN
      switch (aspect[0]) {
        case 'free':
          response = NaN
          break
        case 'custom':
          response = this.options.aspectWidth / this.options.aspectHeight
          break
        default:
          response = aspect[0] / aspect[1]
          break
      }

      return response
    },
    async onLoadedFile (file) {
      this.data.url = URL.createObjectURL(file)
      this.data.name = file.name
      this.data.type = file.type
      this.data.loaded = true
    }
  }

}
</script>
<style lang="scss">
.cropper-editor.error {
  .cropper-view-box {
    outline: 1px solid red !important;
    outline-color: rgba(255, 0, 0, 0.75) !important;
  }
  .cropper-line,
  .cropper-point {
    background-color: red !important;
  }
}
</style>
<style lang="scss" scoped>
.cropper-editor {
  .capitalize {
    text-transform: capitalize;
  }
  .form-cropper {
    border: 1px solid #eee;
    padding: 15px;
    overflow: auto;
    height: 100vh;
    ul.list-features {
      line-height: 2;
      padding-left: 1rem;
      & > li {
        list-style-type: none;
      }
    }
  }
}
</style>
