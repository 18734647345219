import request from '@/utils/request'

export function login (data) {
  return request({
    url: '/api-manager/login',
    method: 'post',
    data
  })
}

export function getInfo () {
  return request({
    url: '/api-manager/admin',
    method: 'get'
  })
}

export function logout () {
  return request({
    url: '/api-manager/logout',
    method: 'post'
  })
}

export function getAdminSchema () {
  return request({
    url: '/api-manager/admin/schema',
    method: 'get'
  })
}

export function getAdminList (params) {
  return request({
    url: '/api-manager/list-admin',
    method: 'get',
    params
  })
}

export function storeAdmin (data) {
  return request({
    url: '/api-manager/admin',
    method: 'post',
    data
  })
}
export function updateAdmin (_id, data) {
  return request({
    url: '/api-manager/admin/' + _id,
    method: 'put',
    data
  })
}
export function getAdmin (_id) {
  return request({
    url: '/api-manager/admin/' + _id,
    method: 'get'
  })
}
export function destroyAdmin (_id) {
  return request({
    url: '/api-manager/admin/' + _id,
    method: 'delete'
  })
}
export function getScopes () {
  return request({
    url: '/api-manager/admin/scopes',
    method: 'get'
  })
}
