// import _ from 'lodash'

export default {
  props: {
    vid: {
      type: String
    },
    frontRules: {
      type: [Array, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    },
    keyComponent: {
      type: String,
      default: ''
    },
    inputRef: {
      type: String
    },
    configElement: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    innerValue: '',
    storeComponent: {}
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  beforeCreate () {
    delete this.$attrs.input
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  computed: {
    rules: function () {
      const isArray = Array.isArray(this.frontRules)
      if (!isArray) {
        return this.frontRules
      }

      const values = []
      for (const item of this.frontRules) {
        values.push(item.value)
      }
      return values.join('|')
    },
    inputListeners: function () {
      // var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners
      )
    }
  }
}
