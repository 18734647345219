import _ from 'lodash'

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime (time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj (url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {string} title
 * @param {string} pageTitle
 * @returns {string}
 */
export function getPageTitle (title = 'Probosque', pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

/**
 * @param {Object} elementsObject
 * @returns {Array}
 */
export function objectToArray (elementsObject) {
  return Object.keys(elementsObject).map(function (key) {
    const clon = _.cloneDeep(elementsObject[key])
    clon.key = key
    return clon
  })
}

/**
 * @param {Array} elementsArray
 * @returns {Object}
 */
export function arrayToObject (elementsArray) {
  const objElements = {}
  elementsArray.forEach(function (item) {
    const key = item.key
    delete item.key
    objElements[key] = _.cloneDeep(item)
  })

  return objElements
}

/**
 * @param {Object} obj
 * @param {Object} merge
 * @returns {Object}
 */
export function mergeObject (obj, merge) {
  for (const [key, value] of Object.entries(merge)) {
    obj[key] = value
  }
}

/**
 * @param {Array} arraySearch
 * @param {mixed} value
 * @param {string} key
 * @returns {number}
 */
export function findKeyValue (arraySearch, value, key = 'key') {
  return arraySearch.findIndex(function (item) {
    return item[key] === value
  })
}

/**
     * Convert Object from camelCase to kebab-case object components
     * @returns {object}
     * @param objectElements
     */
export function mapKebabComponents (objectElements) {
  const groupComponents = {}
  for (const [key, value] of Object.entries(objectElements)) {
    groupComponents[camelToKebab(key)] = value
  }

  return groupComponents
}

/**
 * @param {string} text
 * @returns {string}
 */
// export function slugify (text) {
//   return text
//     .toString() // Cast to string
//     // .toLowerCase() // Convert the string to lowercase letters
//     .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
//     .trim() // Remove whitespace from both sides of a string
//     .replace(/\s+/g, '-') // Replace spaces with -
//     .replace(/[^\w\-]+/g, '') // Remove all non-word chars
//     .replace(/\-\-+/g, '-') // Replace multiple - with single -
// }

export function stringToSlug (str) {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  // str = str.toLowerCase()
  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_;'
  var to = 'aaaaeeeeiiiioooouuuunc----'
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }
  str = str.replace(/[^a-zA-Z0-9,:-]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}
/**
     * Convert Strings from camelCase to kebab-case
     * @returns {string}
     * @param input
     */
export function camelToKebab (input) {
  return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

/**
   * Convert Strings from kebab-case to camelCase
   * @returns {string}
   * @param input
   */
export function kebabToCamel (input) {
  return input.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase()
  })
}
