import Vue from 'vue'
Vue.mixin({
  filters: {
    statusColor (status) {
      const statusMap = {
        true: 'primary',
        false: 'danger'
      }
      return statusMap[status]
    },
    statusFilter (status) {
      const statusMap = {
        true: 'Enabled',
        false: 'Disbled'
      }
      return statusMap[status]
    }
  },
  methods: {
    responseCatch (e) {
      const data = e.response.data
      console.log('responseCatch -> data', data)
      if (data.messages) {
        data.messages.forEach((element) => {
          this.$message.error(element)
        })
      }
    }
  }
})
