import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/layout'

Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [

  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: { title: 'Dashboard', icon: 'el-icon-s-home' }
      }
    ]
  }
  // { path: '*', component: Layout, redirect: '/dashboard', hidden: true }
]

export const asyncRoutes = [
  {
    path: '/users',
    component: Layout,
    meta: {
      // scopes: ['admins']
    },
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/users/index'),
        meta: { title: 'Usuarios App', icon: 'el-icon-mobile' }
      }
    ]
  },
  {
    path: '/stripe',
    component: Layout,
    name: 'stripe',
    meta: {
      icon: 'el-icon-bank-card',
      title: 'Stripe'
    },
    children: [
      {
        path: '/stripe/contributions',
        name: 'stripe.contributions',
        component: () => import('@/views/stripe/contributions/index'),
        meta: { title: 'Donaciones', icon: 'el-icon-coin' }
      },
      {
        path: '/stripe/suscriptions',
        name: 'stripe.subscriptions',
        component: () => import('@/views/stripe/subscriptions/index'),
        meta: { title: 'Suscripciones', icon: 'el-icon-tickets' }
      },
      {
        path: '/stripe/events',
        name: 'stripe.events',
        component: () => import('@/views/stripe/events/index'),
        meta: { title: 'Eventos', icon: 'el-icon-view' }
      },
      {
        path: '/stripe/plan',
        name: 'stripe.plan',
        component: () => import('@/views/stripe/plan/index'),
        meta: { title: 'Planes', icon: 'el-icon-edit-outline' }
      },
      {
        path: '/stripe/create',
        name: 'stripe.create',
        component: () => import('@/views/stripe/plan/create'),
        meta: { title: 'Crear', icon: 'el-icon-view' },
        hidden: true
      }
    ]
  },
  {
    path: '/paypal',
    component: Layout,
    name: 'paypal',
    meta: {
      icon: 'el-icon-bank-card',
      title: 'Paypal'
    },
    children: [
      {
        path: '/paypal/contributions',
        name: 'paypal.contributions',
        component: () => import('@/views/paypal/contributions/index'),
        meta: { title: 'Donaciones', icon: 'el-icon-coin' }
      },
      {
        path: '/paypal/suscriptions',
        name: 'paypal.subscriptions',
        component: () => import('@/views/paypal/subscriptions/index'),
        meta: { title: 'Suscripciones', icon: 'el-icon-tickets' }
      },
      {
        path: '/paypal/events',
        name: 'paypal.events',
        component: () => import('@/views/paypal/events/index'),
        meta: { title: 'Eventos', icon: 'el-icon-view' }
      },
      {
        path: '/paypal/plan',
        name: 'paypal.plan',
        component: () => import('@/views/paypal/plan/index'),
        meta: { title: 'Planes', icon: 'el-icon-edit-outline' }
      },
      {
        path: '/paypal/create',
        name: 'paypal.create',
        component: () => import('@/views/paypal/plan/create'),
        meta: { title: 'Crear', icon: 'el-icon-view' },
        hidden: true
      }
    ]
  },
  {
    path: '/app',
    component: Layout,
    name: 'app',
    meta: {
      icon: 'el-icon-mobile-phone',
      title: 'Aplicación'
    },
    children: [
      {
        path: '/app/home',
        name: 'app.home',
        component: () => import('@/views/app/home'),
        meta: { title: 'Home', icon: 'el-icon-house' }
      },
      {
        path: '/app/AmoChapu',
        name: 'app.AmoChapu',
        component: () => import('@/views/app/chapultepec'),
        meta: { title: 'Amo Chapultepec', icon: 'el-icon-sunny' }
      },
      {
        path: '/app/terms',
        name: 'app.terms',
        component: () => import('@/views/app/terms'),
        meta: { title: 'Terminos', icon: 'el-icon-tickets' }
      },
      {
        path: '/app/escrows',
        name: 'app.escrows',
        component: () => import('@/views/app/escrows'),
        meta: { title: 'Bosque de Chapultepec', icon: 'el-icon-reading' }
      },
      {
        path: '/app/projects',
        name: 'app.projects',
        component: () => import('@/views/app/projects'),
        meta: { title: 'Proyectos', icon: 'el-icon-edit' }
      },
      {
        path: '/app/achievements',
        name: 'app.achievements',
        component: () => import('@/views/app/achievements'),
        meta: { title: 'Logros', icon: 'el-icon-trophy' }
      }
      // {
      //   path: '/app/acknowledgment',
      //   name: 'app.acknowledgment',
      //   component: () => import('@/views/app/acknowledgment'),
      //   meta: { title: 'Agradecimiento', icon: 'el-icon-magic-stick' }
      // }
    ]
  },
  {
    path: '/guides',
    component: Layout,
    meta: {
      // scopes: ['admins']
    },
    children: [
      {
        path: '',
        name: 'guides',
        component: () => import('@/views/guides/index'),
        meta: { title: 'Guias', icon: 'el-icon-guide' }
      },
      {
        path: 'create',
        name: 'guides.create',
        component: () => import('@/views/guides/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'guides.edit',
        component: () => import('@/views/guides/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '/filters',
    component: Layout,
    meta: {
    },
    children: [
      {
        path: '',
        name: '/',
        component: () => import('@/views/filters/index'),
        meta: { title: 'Efectos', icon: 'el-icon-star-on' }

      },
      {
        path: '/filters/create',
        name: 'filters.create',
        component: () => import('@/views/filters/create'),
        meta: {
          title: 'Agregar efecto'
        },
        hidden: true
      },
      {
        path: '/filters/edit/:id',
        name: 'filters.edit',
        component: () => import('@/views/filters/edit'),
        meta: {
          title: 'Editar efecto'
        },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '/interestingPlaces',
    component: Layout,
    meta: {
      // scopes: ['admins']
    },
    children: [
      {
        path: '',
        name: 'interestingPlaces',
        component: () => import('@/views/interestingPlaces/index'),
        meta: { title: 'Lugares de Interés', icon: 'el-icon-place' }
      },
      {
        path: 'create',
        name: 'interestingPlaces.create',
        component: () => import('@/views/interestingPlaces/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'interestingPlaces.edit',
        component: () => import('@/views/interestingPlaces/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '/projects',
    component: Layout,
    children: [
      {
        path: '',
        name: 'projects',
        component: () => import('@/views/projects/index'),
        meta: {
          icon: 'el-icon-data-board',
          title: 'Proyectos'
        }
      },
      {
        path: 'create',
        name: 'projects.create',
        component: () => import('@/views/projects/create'),
        meta: {
          title: 'Crear Proyecto'
        },
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'projects.edit',
        component: () => import('@/views/projects/edit'),
        meta: {
          title: 'Editar Proyecto'
        },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '/achievements',
    component: Layout,
    meta: { title: 'Logros', icon: 'el-icon-trophy' },
    children: [
      {
        path: '',
        name: 'achievements',
        component: () => import('@/views/achievements/index'),
        meta: { title: 'Logros en App', icon: 'el-icon-medal' }
      },
      {
        path: 'users',
        name: 'achievements.users',
        component: () => import('@/views/achievements/indexUser'),
        meta: { title: 'Logro de Usuarios', icon: 'el-icon-medal-1' }
      },
      {
        path: 'create',
        name: 'achievements.create',
        component: () => import('@/views/achievements/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'achievements.edit',
        component: () => import('@/views/achievements/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      }
    ]
  },
  /* {
    path: '/escrow-section',
    component: Layout,
    meta: {
      // scopes: ['admins']
    },
    children: [
      {
        path: '',
        name: 'escrows',
        component: () => import('@/views/escrows/index'),
        meta: { title: 'Fideicomiso', icon: 'el-icon-document' }
      },
      {
        path: 'create',
        name: 'escrows.create',
        component: () => import('@/views/escrows/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'escrows.edit',
        component: () => import('@/views/escrows/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '/reports',
    component: Layout,
    name: 'reports',
    meta: {
      title: 'Reportes',
      icon: 'el-icon-finished'
    },
    children: [
      {
        path: '/annual-reports',
        name: 'reports.annualReports',
        component: () => import('@/views/reports/annualReports/index'),
        meta: { title: 'Reportes Anuales', icon: 'el-icon-s-marketing' }
      },
      {
        path: '/project-reports',
        name: 'reports.projectReports',
        component: () => import('@/views/reports/projectReports/index'),
        meta: { title: 'Reporte de Proyectos', icon: 'el-icon-s-data' }
      },
      {
        path: '/annual-reports/create',
        name: 'reports.annualReports.create',
        component: () => import('@/views/reports/annualReports/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: '/annual-reports/edit/:id',
        name: 'reports.annualReports.edit',
        component: () => import('@/views/reports/annualReports/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      },
      {
        path: '/project-reports/create',
        name: 'reports.projectReports.create',
        component: () => import('@/views/reports/projectReports/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: '/project-reports/edit/:id',
        name: 'reports.projectReports.edit',
        component: () => import('@/views/reports/projectReports/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      }
    ]
  }, */
  {
    path: '/gallery',
    component: Layout,
    meta: {
      // scopes: ['admins']
    },
    children: [
      {
        path: '',
        name: 'gallery',
        component: () => import('@/views/gallery/index'),
        meta: { title: 'Galería', icon: 'el-icon-picture-outline' }
      },
      {
        path: 'create',
        name: 'gallery.create',
        component: () => import('@/views/gallery/create'),
        meta: {
          title: 'Crear'
        },
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'gallery.edit',
        component: () => import('@/views/gallery/edit'),
        meta: {
          title: 'Editar'
        },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '/admin',
    component: Layout,
    meta: {
      scopes: ['admin']
    },
    children: [
      {
        path: '',
        name: 'admin',
        component: () => import('@/views/admin/index'),
        meta: { title: 'Administradores', icon: 'el-icon-s-custom' }
      },
      {
        path: 'edit/:_id',
        name: 'admin.edit',
        component: () => import('@/views/admin/edit'),
        meta: {
          title: 'Editar Usuario'
        },
        hidden: true
      },
      {
        path: 'create',
        name: 'admin.create',
        component: () => import('@/views/admin/create'),
        meta: {
          title: 'Crear Usuario'
        },
        hidden: true
      }
    ]
  },
  { path: '*', component: Layout, redirect: '/dashboard', hidden: true }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes

})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
