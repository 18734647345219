import request from '@/utils/request'
export function getListCollectionTemplate (params = {}) {
  return request({
    url: '/api-manager/collection-template',
    method: 'get',
    params
  })
}
export function getCollectionTemplate (_id) {
  return request({
    url: '/api-manager/collection-template/' + _id,
    method: 'get'
  })
}
export function storeCollectionTemplate (data) {
  return request({
    url: '/api-manager/collection-template',
    method: 'post',
    data
  })
}
export function updateCollectionTemplate (_id, data) {
  return request({
    url: '/api-manager/collection-template/' + _id,
    method: 'put',
    data
  })
}
export function destroyCollectionTemplate (_id) {
  return request({
    url: '/api-manager/collection-template/' + _id,
    method: 'delete'
  })
}

export function getValidateCollectionTemplate (data) {
  return request({
    url: '/api-manager/collection-template/key',
    method: 'post',
    data
  })
}
