import request from '@/utils/request'

export function uploadImageBase64 (data, config) {
  return request({
    url: '/api-manager/uploadImageBase64',
    method: 'post',
    data,
    ...config
  })
}

export function uploadImageFile (data, config) {
  return request({
    url: '/api-manager/image',
    method: 'post',
    data,
    ...config
  })
}

export function updateImageFile (id, data) {
  return request({
    url: `/api-manager/image/${id}`,
    method: 'put',
    data
  })
}
