<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancelDialog"
    :title="dialog.title"
    :visible.sync="isVisible"
  >
    <ValidationObserver ref="dialogForm" v-slot="{ invalid }">
      <el-form :model="localData">
        <input-text
          v-model="localData.key"
          frontRules="required|alpha_dash"
          label="Input key"
          autocomplete="off"
        />

        <input-text
          v-model="localData.label"
          frontRules="required|max:250"
          label="Input label"
          autocomplete="off"
        />

        <input-text
          v-model="localData.helper"
          frontRules="max:500"
          label="Helper text"
          autocomplete="off"
        />

        <el-divider content-position="left">Precision Number</el-divider>
        <input-number
          v-model="localData.precision"
          frontRules="required|integer"
          :min="0"
          label="Precision Digits"
        />
        <el-divider content-position="left">Step Config</el-divider>

        <input-number
          v-model="localData.step"

          frontRules="required"
          label="Step"
        />
        <el-form-item label="Step strictly">
          <el-switch v-model="localData.stepStrictly"> </el-switch>
        </el-form-item>

        <el-form-item label="Show controls">
          <el-switch v-model="localData.controls"> </el-switch>
        </el-form-item>

        <el-form-item label="Set Limit">
          <el-switch
          v-model="localData.setLimit"

            @change="deleteProperty(['min', 'max'], !localData.setLimit)"

          > </el-switch>
        </el-form-item>

        <template v-if="localData.setLimit">
          <input-number
            v-model="localData.min"
            frontRules="required"
            label="Min Value"
          />
          <input-number
            v-model="localData.max"
            frontRules="required"
            label="Max Value"
          />
        </template>
        <select-rules
          :configRules="dialog.frontRules"
          :rules.sync="data.frontRules"
        >
          <template v-slot:title>
            Add or Create FrontEnd Rules Validate
          </template>
          <template v-slot:link>
            <el-link
              target="_blank"
              href="https://logaretm.github.io/vee-validate/guide/rules.html#rules"
              icon="el-icon-document"
              >View Available Validation Rules - VeeValidate
            </el-link>
          </template>
        </select-rules>

        <select-rules
          :configRules="dialog.backRules"
          :rules.sync="data.backRules"
        >
          <template v-slot:title>
            Add or Create BackEnd Rules Validate
          </template>
          <template v-slot:link>
            <el-link
              target="_blank"
              href="https://laravel.com/docs/8.x/validation#available-validation-rules"
              icon="el-icon-document"
              >View Available Validation Rules - Laravel</el-link
            >
          </template>
        </select-rules>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">Cancel</el-button>

        <el-button
          v-if="isEdit"
          type="primary"
          :disabled="invalid"
          @click="editDialog"
          >Edit</el-button
        >

        <el-button
          v-if="!isEdit"
          type="primary"
          :disabled="invalid"
          @click="saveDialog"
          >Save</el-button
        >
      </span>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import mixinDialog from './mixin/dialogForm'
import selectRules from '../Forms/SelectRules'
export default {
  components: {
    'select-rules': selectRules
  },
  mixins: [mixinDialog]
}
</script>
