// import _ from 'lodash'

export default {

  data () {
    return {
      localData: this.data
    }
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false
    },
    isEdit: {
      required: true,
      type: Boolean,
      default: false
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    dialog: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    cancelDialog () {
      this.$emit('cancel-dialog')
    },
    saveDialog () {
      // this.localData.backRules = _.cloneDeep(this.elementsBackRules)
      // this.localData.frontRules = _.cloneDeep(this.elementsFrontRules)

      this.$emit('save-dialog', this.localData)
    },
    editDialog () {
      // this.localData.backRules = _.cloneDeep(this.elementsBackRules)
      // this.localData.frontRules = _.cloneDeep(this.elementsFrontRules)

      this.$emit('edit-dialog', this.localData)
    },
    deleteProperty (key, invoke = true) {
      if (!invoke) {
        return
      }
      const isArray = Array.isArray(key)
      if (!isArray) {
        delete this.localData[key]
        return
      }

      key.forEach(element => delete this.localData[element])
    }
  }
}
