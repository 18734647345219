<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.label || $attrs.labelValidation"
    :rules="rules"
    v-slot="{ errors }"
    :ref="keyComponent"
  >
    <el-form-item :error="errors[0]" :label="$attrs.label">
      <i v-if="$attrs.helper">
        {{ $attrs.helper }}
      </i>
      <div @click="openModal()">
        <el-image
          :src="pathStorageThumb"
          :alt="innerValue.alt"
          :title="innerValue.path"
          class="image-preview"
          @error="onErrorImage()"
          @load="onLoadImage()"
        >
          <el-row
            slot="placeholder"
            type="flex"
            class="image-slot"
            align="middle"
            justify="center"
          >
            <i class="el-icon-loading"></i> loading..
          </el-row>

          <el-row
            slot="error"
            type="flex"
            class="image-slot"
            align="middle"
            justify="center"
          >
            <i class="el-icon-picture-outline"></i>
          </el-row>
        </el-image>
      </div>

      <el-link v-if="isLoadedImage" :href="pathStorage" target="_blank"
        >View<i class="el-icon-view el-icon--right"></i>
      </el-link>
      <el-link v-if="isLoadedImage" @click="clear"
        >clean<i class="el-icon-delete el-icon--right"></i>
      </el-link>
    </el-form-item>

    <modal
      :name="keyComponent"
      :width="'98%'"
      :height="'98%'"
      style="z-index: 2000"
      @opened="opened"
    >
      <image-editor-tui
        :options="configElement"
        v-on:saved-image="onSavedImage"
      />
    </modal>
  </ValidationProvider>
</template>

<script>
import imageEditorTui from '../../ImageEditorTui'
import inputElemet from './mixin/inputElemet'
import imageElemet from './mixin/imageElemet'

export default {
  mixins: [inputElemet, imageElemet],
  components: {
    'image-editor-tui': imageEditorTui
  }
}
</script>

<style scoped lang="scss">
.image-preview {
  // width: 300px;
  height: 150px;
  .image-slot {
    width: 225px;
    height: 150px;
    background: #f5f7fa;
    color: #909399;
    i {
      font-size: 30px;
    }
  }
}
</style>
